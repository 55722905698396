<template>
  <div class="orderconfirm-wrap">
    <div class="bread-wrap">
      <span @click="navigateback" class="backbtn"
        ><i class="el-icon-arrow-left"></i
      ></span>
      <span class="bread-title">订单确认</span>
    </div>
    <div class="order-wrap">
      <div class="order-left">
        <p class="title-wrap">
          <i class="iconfont icon-jiaofeizhangdan titleicon"></i
          ><span class="title">订单信息</span>
        </p>
        <div class="ff-detail">
          <span class="dtcontent">线路类型</span
          ><span class="dttext">{{ detail.guige }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">选择区域</span
          ><span class="dttext">{{ detail.region }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">购买数量</span
          ><span class="dttext">{{ detail.num }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">购买时长</span
          ><span class="dttext">{{ detail.sc }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">自动续费</span
          ><span class="dttext">{{ AutoRenewMap[payload.AutoRenew] }}</span>
        </div> 
        <div class="ff-detail">
          <span class="dtcontent">创建时间</span
          ><span class="dttext">{{ nowdate }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">支付方式</span
          ><span class="dttext">{{payText}}</span
          >
        </div>
        <div class="ff-detail">
          <span class="dtprice">合计费用</span
          ><span class="dtprice">{{ TotalAmount }} 元</span>
        </div>
        <el-button
          v-if="amountPay"
          @click="payNow"
          :disabled="payDisable"
          class="paybtn"
          size="small"
          type="primary"
          >立即支付</el-button
        >
      </div>
      <div class="order-right" v-if="!amountPay">
        <div class="qrcode-wrap">
          <div class="qrcode" id="qrcodeImg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AutoRenewMap } from "@/utils/constants";
import formatdate from "@/utils/format";
import navigation from "@/utils/navigation";
import QRCode from "qrcodejs2";
export default {
  name: "lineorderconfirm",
  data() {
    checkTimer: null;
    const date = new Date();
    return {
      TradeNo: "",
      detail: {},
      TotalAmount: 0,
      nowdate: formatdate(parseInt(date.getTime() / 1000), "YYYY-MM-DD"),
      amountPay: false,
      payText: "",
      payload: {},
      payType: "",
      AutoRenewMap: AutoRenewMap,
      payDisable: false
    };
  },
  mounted() {
    // console.log("pay params: " + JSON.stringify(this.$route.params));
    this.detail = this.$route.params.detail;
    this.payload = this.$route.params.payload;
    this.payType = this.detail.PayType
    if (this.detail.PayType == "amount") {
      this.amountPay = true;
      this.payText = "余额支付";
    } else if (this.detail.PayType == "alipay_page") {
      this.amountPay = false;
      this.payText = "支付宝扫码支付"; 
    } else {
      this.amountPay = false;
      this.payText = "微信扫码支付";
    }

    this.$store
      .dispatch("line/createordernumber", { ...this.payload })
      .then((res) => {
        // console.log("create line order response:" + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.TradeNo = res.TradeNo;
          this.TotalAmount = res.TotalAmount/100;
          //实际支付
          if (!this.amountPay) {
            this.getqrcode();
          } 
        } else {
          this.$message.error(res.Message);
        }
      })
      .catch((e) => {
        console.log(e)
        this.$message.error("创建订单失败");
      });
    // console.log(this.$route.params);
  },
  beforeDestroy() {
    console.log("beforeDestroy called.");
    if (this.checkTimer != null) {
      clearInterval(this.checkTimer);
      this.checkTimer = null;
    }
  },
  methods: {
    qrcode(url) {
      return new QRCode("qrcodeImg", {
        width: 230,
        height: 230,
        text: url,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
    navigateback() {
    if (this.checkTimer != null) {
      console.log("navigateback called.");
      clearInterval(this.checkTimer);
      this.checkTimer = null;
    }
      navigation.back();
    },
    navigation(url) {
      navigation.navigateTo({
        url,
      });
    },
    getpayconfirm() {
      this.checkTimer = setInterval(() => {
        this.$store
          .dispatch("line/getpayconfirm", {
            TradeNo: this.TradeNo,
            PayType: this.payType,
            OrderType: "Path"
          })
          .then((res) => {
            if (res.RetCode == 0 && res.Status == "Paid") {
              clearInterval(this.checkTimer);
              this.$message.success("支付成功！");
              this.navigation("linelist");
              //this.$router.push({path:'linelist'});
            } else if (res.RetCode !== 0) {
              clearInterval(this.checkTimer);
            }
          });
      }, 1000);
    },
    getqrcode() {
      const payload = {
        TradeNo: this.TradeNo,
        PayType: this.payType,
        OrderType: "Path"
      };
      this.$store.dispatch("line/getpaymentparams", { ...payload }).then((res) => {
        if (res.RetCode == 0) {
          this.qrcode(res.PayReq);
          this.getpayconfirm();
        }
      });
    },
    payNow() {
      if (this.payDisable) {
        return
      }
      this.payDisable = true
      const payload = {
        TradeNo: this.TradeNo,
        PayType: "amount",
        OrderType: "Path",
      };
      this.$store.dispatch("line/getpaymentparams", { ...payload }).then((res) => {
        // console.log("amount pay response: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.$message.success("支付成功！");
          this.navigation("linelist");
        } else {
          this.$message.error("支付失败！");
        }
      })
      .finally(() => {
        this.payDisable = false
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>