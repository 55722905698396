function formatdate(timestamp, format) {
    const pad = function (val, len) {
        val = String(val)
        len = len || 2
        while (val.length < len) val = '0' + val
        return val
    }

    const date = new Date(parseFloat(timestamp * 1000))
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hour = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const second = pad(date.getSeconds())

    switch (format) {
        case 'MM-DD HH:MM:SS':
            return [month, day].join('-') + ' ' + [hour, minutes, second].join(':')
        case 'YYYY-MM-DD HH:MM':
            return [year, month, day].join('-') + ' ' + [hour, minutes].join(':')
        case 'YYYY-MM-DD':
            return [year, month, day].join('-')
        case 'YYYY/MM':
            return [year, month].join('/')
        case 'MM-DD':
            return [month, day].join('-')
        case 'HH:MM:SS':
            return [hour, minutes, second].join(':')
        case 'HH:MM':
            return [hour, minutes].join(':')
        case 'array':
            return [year, month, day, hour, minutes, second]
        case 'YYYY-MM-DD HH:MM:SS':
            return [year, month, day].join('-') + ' ' + [hour, minutes, second].join(':')
        case 'YYYY年MM月DD日':
            return year + '年' + month + '月' + day + '日'
        case 'YYYY年MM月DD日 HH时MM分':
            return year + '年' + month + '月' + day + '日' + ' ' + hour + '时' + minutes + '分'
        case 'MM月DD日 HH点':
            return month + '月' + day + '日' + ' ' + hour + '点'
        case 'YYYY/MM/DD HH:MM:SS':
            return year + '/' + month + '/' + day + ' ' + [hour, minutes, second].join(':')
        case 'YYYY/MM/DD 午HH:MM:SS':
            if (hour > 12) {
                return year + '/' + month + '/' + day + ' ' + '下午' + (hour - 12) + ':' + minutes + ':' + second
            } else {
                return year + '/' + month + '/' + day + ' ' + '上午' + hour + ':' + minutes + ':' + second
            }
            default:
                return [year, month, day].join('') + ' ' + [hour, minutes, second].join(':')
    }

}


export default formatdate